@import '../setup.scss';
.pill a{
    background-color: $main-bg;
    color: $dark-text;
    margin: 1em auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 1em;
    width: 75%;
    border-radius: 3em;
}
.pill:first-child a{
    margin-top: calc($HeaderHeight + 2em);
}
.pill:hover a{
    background-color: $darker-bg;
}