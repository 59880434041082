@import "../setup.scss";
form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.span {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0.5em 0;
  align-items: center;
}
.span > button {
  justify-content: center;
  width: 100%;
}
.span > h5 {
  margin: 0 auto;
  font-size: 1.5em;
}
.label {
  font-size: 2em;
  font-weight: bold;
}
.input {
  height: 2em;
  border-radius: 0.5em;
}
.button {
  height: 2em;
  border-radius: 2em;
  font-size: 1.5em;
  font-weight: bold;
  background-color: $black;
  color: $light-text;
}
.button:active {
  background-color: $darker-bg;
  color: $dark-text;
}
